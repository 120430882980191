import { Injectable } from '@angular/core';
import { TcemsService } from '../api/tcems.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { selectCurrentUser, selectTwilioReservation, selectUserState } from '../state/app.state';
import { User, UserState } from '../model/util/user';
import { ReservationAccepted, ReservationCancelled, ReservationCreated, ReservationRejected, ReservationTimeout, ReservationWrapup, WorkerReady, WorkerStatusChanged } from '../actions/twilio.actions';
import { BehaviorSubject } from 'rxjs';
import { updateUserState } from '../actions/api/tcems.actions';
import { Task } from '../model/comm/Task';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {





  constructor(private tcemsService: TcemsService,
    private store: Store ) 
  { 

    this.reservation$.subscribe
    (
      r =>
      {
        this.reservation = r.reservation;
      }
    );

    //Keep user state up to date with the store
  
    this.userState$.subscribe(
      us =>
      {
        if(us != null)
        {
          this.currentState = Object.assign(new UserState(), us);
        }
      }
    )


    this.currentUser$.subscribe(
      u => 
      {
        if(u != null)
        {
          this.currentUser = Object.assign(new User(),u); 
          if ( this.currentUser.twilioWorkerSid != null )
          {

            this.tcemsService.getWorkspaceSid( "nurses" ).toPromise().then
            (
              workspaceSid => {
                this.workspaceSid = workspaceSid["token"];
                if ( this.workspaceSid != null ) {
                  this.bindTaskRouter();
                }
              }
            );
          }

        }
      });


    //Update our reservation and push status updates based 
    //on reservation status change
    /*
    this.reservation$.subscribe
    (
      r =>
      {
        if(r.reservation != null)
        {
          this.reservation = r.reservation;
          switch ( r.reservationStatus )
          {
            case 'Accepted':
              this.worker.update({"ActivitySid":this.activitySids["Busy"][0].sid});
              break;
            case 'Created':
                this.worker.update({"ActivitySid":this.activitySids["Chat"][0].sid});
                break;
              case 'WrapUp':
              this.worker.update({"ActivitySid":this.activitySids["WrapUp"][0].sid});
              break;
            default:
              this.worker.update({"ActivitySid":this.activitySids["Away"][0].sid});
              break;
          }
        }
      }
    );
*/    
  }

  worker: any;
  voice: any;
  activitySids: {name:string,sid:string}[];
  workspaceSid: string;

  currentUser$ = this.store.select(selectCurrentUser);
  currentUser : User;

  userState$ = this.store.select(selectUserState);
  currentState: UserState;

  reservation$ = this.store.select(selectTwilioReservation);
  reservation: any;
  reservationStatus: string;


  private twilioNumber = new BehaviorSubject<string>("");
  currentTwilioNumber = this.twilioNumber.asObservable();

  updateTwilioNumber( _twilioNumber: string )
  {
    this.twilioNumber.next( _twilioNumber );
  }

  // createReservation()
  // {
  //   if ( this.reservation.task.attributes.type == "CHAT" )
  //   {
  //     _this.updateButton( "Chat", reservation.task.attributes.memberId, _this );
  //     //confirm("You have an incoming chat!");
  //     //reservation.accept();
  //     let url = environment.chatURI + "?conversationSid=" + reservation.task.attributes.conversationSid
  //                                     + "&taskSid=" + reservation.task.sid
  //                                     + "&userId=" + _this.currentUser.userId
  //                                     + "&name=" + _this.currentUser.firstName;
  //     _this.startChat(url);
  //   }
  //   else if ( reservation.task.attributes.type == "OUTBOUND" )
  //   {
  //     _this.updateButton( "Outbound", reservation.task.attributes.memberEndpoint, _this );
  //     _this.tcemsService.connectCall(
  //       reservation.task.attributes.nurseEndpoint,
  //       reservation.task.attributes.callerId,
  //       reservation.task.attributes.nurseEndpoint,
  //       reservation.task.attributes.memberEndpoint,
  //       "Connecting outbound call to " + reservation.task.attributes.memberFirstName + " " + reservation.task.attributes.memberLastName,
  //       25  // timeout (seconds)
  //     );
  //   }
  //   else  // incoming phone call
  //   {
  //     _this.updateButton( "Call", reservation.task.attributes.from.replace( "+1", "", _this ) );
  //     _this.taskrouterWhiteLabel = reservation.task.attributes.program.toUpperCase() + " " + reservation.task.attributes.from;
  //     // reservation.dequeue();

  //   }
  // }




  async bindTaskRouter() : Promise<void> { 

    return this.tcemsService.getTaskRouterCapabilityToken( this.workspaceSid, this.currentUser.twilioWorkerSid ).toPromise().then
    (

      token => {
        this.worker = new Twilio.TaskRouter.Worker( token.token );
        this.worker.activities.fetch
        (
          (error, activityList) => 
          {
            this.activitySids = activityList.data.map(d => ({sid:d.sid, name:d.friendlyName}));
          
           //await this.getAgentActivityStates();


            //Set the current worker status
            this.store.dispatch({type: WorkerStatusChanged.type,workerStatus:this.worker.activityName});


            //Set up events for this worker
            this.worker.on('ready', (worker) => 
              {
                this.store.dispatch({type: WorkerReady.type,worker:worker});
                this.store.dispatch({type: WorkerStatusChanged.type,workerStatus:worker.activityName});
              });
            this.worker.on('activity.update', (worker) => this.store.dispatch({type: WorkerStatusChanged.type,workerStatus:worker.activityName}));
            this.worker.on('reservation.created', (reservation) => this.store.dispatch({type: ReservationCreated.type,reservation:reservation}));
            this.worker.on('reservation.accepted', (reservation) => this.store.dispatch({type: ReservationAccepted.type,reservation:reservation}));
            this.worker.on('reservation.rejected', (reservation) => this.store.dispatch({type: ReservationRejected.type,reservation:reservation}));
            this.worker.on('reservation.timeout', (reservation) => this.store.dispatch({type: ReservationTimeout.type,reservation:reservation}));
            this.worker.on('reservation.canceled', (reservation) => this.store.dispatch({type: ReservationCancelled.type,reservation:reservation}));
            this.worker.on('reservation.wrapup', (reservation) => this.store.dispatch({type: ReservationWrapup.type,reservation:reservation}));
            //this.MakeWorkerReady();
          }
        )
        
      }
    )//.then(r => this.MakeWorkerReady());
}

  // async getAgentActivityStates() : Promise<any> {
  //   var activitySids = {};
  //   // Fetch the full list of available Activities from TaskRouter. Store each
  //   // ActivitySid against the matching Friendly Name
  //   await this.worker.activities.fetch( async (error, activityList) =>
  //     {
  //       var activities = activityList.data;
  //       var i = activities.length;
  //       while (i--) {
  //         activitySids[activities[i].friendlyName] = [];
  //         activitySids[activities[i].friendlyName].push({sid: activities[i].sid, name: activities[i].friendlyName});
  //       }
  //     });
  //   this.activitySids = activitySids
  // }

  MakeWorkerReady()
  {
    this.worker.update({"ActivitySid": this.activitySids.find((a) => a.name == "Ready").sid});
  }

  MakeWorkerAway()
  {
    this.worker.update({"ActivitySid": this.activitySids.find((a) => a.name == "Away").sid});
  }

  MakeWorkerDesk()
  {
    this.worker.update({"ActivitySid": this.activitySids.find((a) => a.name == "Desk").sid});
  }
  
  MakeWorkerBusy()
  {
    this.worker.update({"ActivitySid": this.activitySids.find((a) => a.name == "Busy").sid});
  }
  
  MakeWorkerVacation()
  {
    this.worker.update({"ActivitySid": this.activitySids.find((a) => a.name == "Vacation").sid});
  }
  
  MakeWorkerAcceptReservation()
  {
    if ( ( this.currentState.activeTask == null || this.currentState.activeTask.externalId == null ) && this.reservation.reservationStatus == "pending" )
    {
      this.tcemsService.acceptTask( this.reservation.taskSid );

      let us = Object.assign(new UserState(), this.currentUser.userState);

      us.activeTask = Object.assign(new Task(), this.reservation.task);
      us.activeTask.externalId = this.reservation.task.sid;

      this.store.dispatch({type: updateUserState.type, userState: us});

    }

    // else if ( this.reservation.reservationStatus == "accepted" ) {
    else if ( this.currentState.activeTask != null && this.currentState.activeTask.externalId != null) 
    {

      let t = this.currentState.activeTask;
      // this.tcemsService.sendMessage(t.attributes["conversationId"],null,"Nurse "+this.currentUser.firstName+" has left the chat.");
      // this.tcemsService.completeTask( t.externalId );
      this.tcemsService.hangupConversation(t.externalId, parseInt(t.attributes["conversationId"]));

      let us = Object.assign(new UserState(), this.currentState);

      us.activeTask = null;

      this.store.dispatch({type: updateUserState.type, userState: us});

    } else {
      // ignore the click
    }
  }


  // currentReservation;

  // url = ""

  // setUpTaskRouterCallbacks(ts: TwilioService) : void {
  //   this.worker.on( 'ready', function(worker) 
  //   {
      
  //     ts.taskRouterListeners .updateButton( worker.activityName, "", _this );
  //   });

  //   this.worker.on('activity.update', function(worker) {
  //     _this.updateButton( worker.activityName, "", _this );
  //   });

  //   this.worker.on("reservation.created", function(reservation) {
  //     this.reservation = reservation;
      
  //   });

  //   this.worker.on("reservation.accepted", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Busy"][0].sid});
  //     _this.updateButton( "Busy", "", _this );
  //   });

  //   this.worker.on("reservation.rejected", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Away"][0].sid});
  //     _this.updateButton( "Away", "", _this );
  //   });

  //   this.worker.on("reservation.timeout", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Away"][0].sid});
  //     _this.updateButton( "Away", "", _this );
  //   });

  //   this.worker.on("reservation.canceled", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Away"][0].sid});
  //     _this.updateButton( "Away", "", _this );
  //   });

  //   this.worker.on("reservation.wrapup", function(reservation) {
  //     console.log("wrapup: " + _this.activitySids["WrapUp"][0].sid)
  //     _this.worker.update({"ActivitySid": _this.activitySids["WrapUp"][0].sid});
  //     _this.worker.completeTask(reservation.taskSid,
  //       function(error,completedTask) { });
  //     _this.updateTwilioNumber("");
  //     _this.endChat();
  //   });
    
  // }

  isChat: boolean;



}