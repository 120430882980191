export const environment = {
  production: true,
  azureAdRedirectUri: 'https://hemi.1.harlowehealth.com',
  tcemsAPIBase: 'https://hemi.1.api.harlowehealth.com',
  logoutRedirect: 'https://hemi.1.harlowehealth.com',
  azureAdClientId: '217fef7b-f452-4620-bd67-5d160f93f153',
  azureAdAuthority: 'https://login.microsoftonline.com/0c7cab0e-8f19-4bde-a901-4b7b35f056e3',
  tcemsAPI_phi: '/api/phi',
  tcemsAPI_edi: '/api/edi',
  tcemsAPI_util: '/api/util',
  tcemsAPI_claims: '/api/claims',
  tcemsAPI_encounter: '/api/encounter',
  tcemsAPI_support: '/api/support',
  tcemsAPI_reports: '/api/reporting',
  tcemsAPI_finance: '/api/finance',
  tcemsAPI_provider: '/api/provider',
  tcemsAPI_geocode: '/api/geocode',
  tcemsAPI_comm: '/api/comm',
  tcemsAPI_job: '/api/jobs',
  tcemsAPI_publicapi: '/api/publicapi',
  harlowe: 
  {
    hubs:
    {
      presence: 
      {
        endpoint: '/hubs/presence',
      }
    }
  },
  // tcemsAPIScope_phi: 'api://6c872f6a-62ec-466d-9772-22668957cf4f/phi',
  // tcemsAPIScope_util: 'api://6c872f6a-62ec-466d-9772-22668957cf4f/util',
  // tcemsAPIScope_Claims: 'api://6c872f6a-62ec-466d-9772-22668957cf4f/claims',
  // tcemsAPIScope_Support: 'api://6c872f6a-62ec-466d-9772-22668957cf4f/support',
  // tcemsAPIScope_Encounters: 'api://6c872f6a-62ec-466d-9772-22668957cf4f/encounters',
  // tcemsAPIScope_Finance: 'api://6c872f6a-62ec-466d-9772-22668957cf4f/finance',
  // tcemsAPIScope_Reports: 'api://6c872f6a-62ec-466d-9772-22668957cf4f/reports',
  azureAPIBase: 'https://graph.microsoft.com',
  azureAPI_me: '/v1.0/me',
  azureAPIScope_me: 'user.read',
  devUserId:-1,
  version:"1.60h",
  azureAdConsentScopes: 
  [
    '217fef7b-f452-4620-bd67-5d160f93f153/.default'
    // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/phi',
    // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/util',
    // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/claims',
    // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/encounters',
    // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/support',
    // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/finance',
    // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/reports',
    // 'User.Read',
    // 'Mail.Send',
    // 'Mail.ReadWrite'
  ],
  azureAdProtectedResourceMap: 
  [
    ['https://hemi.1.api.harlowehealth.com',
      [ 
        '217fef7b-f452-4620-bd67-5d160f93f153/.default'
        // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/phi',
        // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/util',
        // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/claims',
        // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/encounters',
        // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/support',
        // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/finance',
        // 'api://6c872f6a-62ec-466d-9772-22668957cf4f/reports'        
      ]
    ],
    ['https://graph.microsoft.com/v1.0/me',['user.read']]

  ] as [string, string[]][], //If we don't give the explicit cast, then the module.ts import statement bitches at us...
  chatURI:'https://reinhardt.tandemcare.com:5561',
  defaultEmailDomain:"harlowehealth.com"

};
