import { Component, ViewChild, OnInit, Input, ChangeDetectorRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { LoginService, Login } from './login/login.service';
import { AbstractLoginStatusListener } from './login/login.status.listener';
import { ConfirmationService, MenuItem, PrimeNGConfig, OverlayOptions } from 'primeng/api';
import { InstanceComponent } from './research/instance/instance.component';
import { InstanceOfCare } from './model/claim/instance_of_care';
import { MatchingComponent } from './encounters/matching/matching.component';
import { MatchSummary } from './model/claim/match_summary';
import { Store } from '@ngrx/store';
import { TcemsService } from './api/tcems.service';
import { ProcedureLookupComponent } from './research/instance/procedure-lookup/procedure-lookup.component';
import { EncounterLookupComponent } from './encounters/encounter-lookup/encounter-lookup.component';
import { selectCurrentUser, selectTwilioReservation, selectTwilioWorkerStatus, selectUserState } from './state/app.state';
import { User, UserState } from './model/util/user';
import { userReducer } from './reducers/api/tcems/globals.reducer';
import { updateUserState } from './actions/api/tcems.actions';
import { ProviderResearchComponent } from './research/providers/provider-research/provider-research.component';
import { environment } from 'src/environments/environment';
import { PhiResult, PhiSearchResult } from './phi/phi-search/phi-search.component';
import { Phi } from './model/phi/phi';
import { TcemsUtilitiesService } from './util/tcems-utilities.service';
import { fromEvent } from 'rxjs';
import { delay } from 'rxjs/operators';
import { _USER_RUNTIME_CHECKS } from '@ngrx/store/src/tokens';
import { HomePageComponent } from './base/home-page/home-page.component';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TwilioService } from './services/twilio.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent extends AbstractLoginStatusListener implements OnInit
{
  @ViewChild(HomePageComponent) homePageComponent: HomePageComponent;
  @ViewChild('phiResults') phiResults: OverlayPanel;
  @ViewChild('twilioDiv') twilioButton;

  buttonEvent: PointerEvent = new PointerEvent("click",null);
  showResults: boolean = false;

  loggedIn: boolean = false;
  topMenuModel: MenuItem[];
  sideMenuModel: MenuItem[];

  currentUser$ = this.store.select(selectCurrentUser);
  currentUser : User;

  allowAdminAccess: boolean = false;
  

  currentUserState$ = this.store.select(selectUserState);
  currentUserState : UserState;

  version: string = environment.version;

  // Twilio task router stuff
  
  taskrouterButtonLabel: string;
  taskrouterWhiteLabelAndCid: string;
  taskrouterButtonClass: string;
  phiSearchResults:PhiSearchResult[];

  //Center panel IDs
  HOME_PAGE = 'homepage';
  RESEARCH_PLAN = 'researchplan';
  RESEARCH_PHI = 'researchphi';
  RESEARCH_LOOKUP_INSTANCE = 'lookupinstance';
  RESEARCH_SEARCH_INSTANCE = 'searchinstance';
  RESEARCH_PROCEDURE_LOOKUP = 'procedurelookup';
  ENCOUNTER_MATCH = 'encountermatch';
  ENCOUNTER_LOOKUP = 'encounterlookup';
  UTILITY_TRANSACTION_MANAGER = 'transactionmanager';  
  UTILITY_INVOICE_MANAGER = 'invoicemanager'; 
  UTILITY_COMPANY_MANAGER = 'companymanager';
  RESEARCH_MANAGE_PRACTICE = "researchmanagepractice";
  REPORTS_REPORT_RUNNER = "reportrunner";
  REPORTS_REPORT_VIEWER = "reportviewer";
  REPORTS_REPORT_MANAGER = "reportmanager";

  //Side menu blocks
  SM_RESEARCH = 'research';

  components = {}; 
  visibility = {}; 

  //Home page toggle
  showHomePage: boolean = true;
  chatComponentURI: string = environment.chatURI;


  workerStatus$ = this.store.select(selectTwilioWorkerStatus);
  reservation$ = this.store.select(selectTwilioReservation);

  twilioButtonOptions : MenuItem[]; 

  // //These are our various implementors for ui elements throughout the app
  // instanceLookupComponent:  InstanceComponent<InstanceOfCare>;
  // instanceSearchComponent:  InstanceComponent<InstanceOfCare>;
  // encounterMatchComponent: MatchingComponent;
  // procedureLookupComponent: ProcedureLookupComponent;
  // encounterLookupComponent: EncounterLookupComponent;


  // //Visibility of each sub component
  // vResearchPlan: boolean = false;
  // vResearchPhi: boolean = false;
  // vLookupInstance: boolean = false;
  // vSearchInstance: boolean = false;
  // vEncounterMatch: boolean = false;
  // vProcedureLookup: boolean = false;
  // vEncounterLookup: boolean = false;
  // vTransactionManager: boolean = false; 

  CPT_NONE: string = "none";
  CPT_SINGLE: string = "single";
  CPT_SPLIT_2_10: string = "split_2_10";

  centerPanelType: string = this.CPT_NONE;

  loginStatusChange(status: Login) 
  {
      this.loggedIn = status.loggedIn;
  }
  
  title = 'HEMI';

  constructor(private loginService: LoginService, 
    private store: Store, 
    private tcemsService: TcemsService, 
    private util: TcemsUtilitiesService,
    private cdr: ChangeDetectorRef,
    private twilioService: TwilioService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    public twilio: TwilioService,
    private primengConfig: PrimeNGConfig
    ) 
    { 
  
      super(loginService);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false
      //Handle routes directly into an encounter
      this.route.paramMap.
      subscribe
      (
        (params: ParamMap) => 
        {
          let id = +params.get('id');
          if(id != 0)
          {
            console.log(id);
            // this.tcemsService.getEncounter(id).toPromise().then
            // (
            //   encounter => this.loadEntityFromEncounter(encounter)
            // )
          }
          
        }
      )
    }



  ngOnInit() : void
  {
    // this.urlSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(this.chatComponentURI);
    // this.components[this.RESEARCH_LOOKUP_INSTANCE] = new InstanceComponent(this.tcemsService);
    // this.components[this.RESEARCH_LOOKUP_INSTANCE].searchType = "instance"; //This one is instance based
    // this.components[this.RESEARCH_SEARCH_INSTANCE] = new InstanceComponent(this.tcemsService);
    // this.components[this.RESEARCH_SEARCH_INSTANCE].searchType = "search"; //We want this one to be search based instead of instance lookup based
    // // this.components[this.ENCOUNTER_MATCH] = new MatchingComponent(this., this.store,this.tcemsService,this.util,);
    // this.components[this.RESEARCH_PROCEDURE_LOOKUP] = new ProcedureLookupComponent(this.tcemsService, this.store, this.confirmationService );
    // this.components[this.ENCOUNTER_LOOKUP] = new EncounterLookupComponent(this.tcemsService, this.store);
    // this.components[this.RESEARCH_MANAGE_PRACTICE] = new ProviderResearchComponent(this.tcemsService,this.cdr);

    // this.primengConfig.overlayOptions = {
    //   mode: 'overlay',
    //   autoZIndex: true,
    //   baseZIndex: 200
    // } as OverlayOptions;
    //this.primengConfig.overlayOptions.mode = 'overlay';


    super.ngOnInit();

    //And our default side menu model

    // Load HOME_PAGE as default
    this.centerPanelType = this.CPT_SINGLE;
    this.changeCenterView(this.HOME_PAGE);

    //Pay attention to the routes that get us here
    // this.route.queryParams.subscribe(params => {
    //   this.changeCenterView(params['main_view']);
    // });


    //Router connections
    //The last entry in the path is what we want to show in our main view
    //There could also be a parameter available to us
    //Available routes:
    // /encounter?[id]


    this.workerStatus$.subscribe
    (
      s =>
      {
        this.updateButton(s);
      }
    )

    this.reservation$.subscribe
    (
      r =>
      {
        let reservationStatus = r.reservationStatus.toLowerCase();

        if( ( r.reservation!= null ) && ( ( reservationStatus == "created" ) || ( reservationStatus == "accepted" ) || ( r.reservation.reservationStatus == "pending" ) ) )
        {
          if( r.reservation.task.attributes.type == "CHAT" && reservationStatus == "created" )
          {
            if ( r.reservation.task.attributes.memberId != "" ) {
              this.phiSearch({'query':r.reservation.task.attributes.memberId});
              this.showResults = true;
            }
            this.updateButton("Chat");
            this.playChime();
          }
          else if( r.reservation.task.attributes.type == "OUTBOUND")
          {
            //this.phiSearch({'query':r.reservation.task.attributes.memberEndpoint});
            //this.showResults = true;
            //this.updateButton("Outbound");
            // this.tcemsService.connectCall(
            //           r.reservation.task.attributes.nurseEndpoint,
            //           r.reservation.task.attributes.callerId,
            //           r.reservation.task.attributes.nurseEndpoint,
            //           r.reservation.task.attributes.memberEndpoint,
            //           "Connecting outbound call to " + r.reservation.task.attributes.memberFirstName + " " + r.reservation.task.attributes.memberLastName,
            //           25);  // timeout (seconds)
          }
          else if(r.reservation.task.attributes.type == "VOICE")
          {
            this.updateButton("Ringing");
            this.showResults = true;
            var fromNumber = r.reservation.task.attributes.from.replace("+1","");
            this.phiSearch({'query':fromNumber});
            switch( fromNumber ) {
              case "266696687":  // ANONYMOUS
              case "7378742833":  // RESTRICTED
              case "2562533":  // BLOCKED
              case "8656696":  // UNKNOWN
              case "86282452253":  // UNAVAILABLE
                fromNumber = "[UNKNOWN]";
                break;
              default:
                fromNumber = fromNumber.substr( 0,3 ) + "-" + fromNumber.substr( 3,3 ) + "-" + fromNumber.substr( 6 );
                break;
            }
            this.taskrouterWhiteLabelAndCid = r.reservation.task.attributes.program.toUpperCase() + ": " + fromNumber;
          }
        }
        else
        {
          this.showResults = false;
        }
      }
    )


      this.currentUser$.subscribe(
        u => 
        {
          if(u != null)
          {
            this.currentUser = Object.assign(new User(),u); 
            if(u.roles.map(r => r.role).includes("Admin"))
            {
              this.allowAdminAccess = true;
            }

            

            //set up our top model
            this.topMenuModel = this.getTopMenuModel();


          }
        });
        
        this.currentUserState$.subscribe(
          u => 
          {
            if (u==null) return;

            this.currentUserState = Object.assign(new UserState(),u);
          });
        
      //Listen for User State Changes
      let source$ = fromEvent(window, 'storage');
      source$.pipe(delay(2000)).subscribe
      (
        storageEvent =>
        {
          let action = localStorage.getItem('USER_STATE');
          if(action != null)
          {
            //If state has changed, then update...
            if(action != JSON.stringify(this.currentUserState))
              this.store.dispatch({type: updateUserState.type, userState: JSON.parse(action)});
          }
        }
      )


      this.twilioButtonOptions= [
        {label: 'Away', command: () => {this.twilio.MakeWorkerAway();}},
        {label: 'Ready', command: () => {this.twilio.MakeWorkerReady();}},
        {label: 'Desk', command: () => {this.twilio.MakeWorkerDesk(); }},
        {label: 'Busy', command: () => {this.twilio.MakeWorkerBusy(); }},
        {label: 'Vacation', command: () => {this.twilio.MakeWorkerVacation(); }}
    ];
    


  }

  taskrouterButtonClick() : void {
    switch( this.taskrouterButtonLabel ) {
      case 'Away':
        this.twilio.MakeWorkerReady();
        break;
      case 'Busy':
        this.twilio.MakeWorkerAway();
        break;
        // this.twilio.MakeWorkerAcceptReservation();
      case 'Chat':
        this.twilio.MakeWorkerAcceptReservation();
        break;
      default:
        this.twilio.MakeWorkerAway();

      // case 'Ready':
      //   // this.showMessages();
      //   this.worker.update({"ActivitySid": this.activitySids["Away"][0].sid});
      //   break;
      // case 'WrapUp':
      //   this.worker.update({"ActivitySid": this.activitySids["Away"][0].sid});
      //   break;
      // case 'Call':
      //   this.worker.update({"ActivitySid": this.activitySids["Away"][0].sid});
      //   break;
      // case 'Outbound':
      //   this.worker.update({"ActivitySid": this.activitySids["Away"][0].sid});
      //   break;
      // case 'Chat':
      //   this.worker.update({"ActivitySid": this.activitySids["Away"][0].sid});
      //   break;
      // case "Busy":
      //   this.worker.update({"ActivitySid": this.activitySids["Away"][0].sid});
      //   this.worker.completeTask(this.worker.reservation.taskSid,
      //     function(error,completedTask) { });
      //     break;
    }
  }

  taskrouterDropDownClick() : void 
  {
    
  }

  //Handle the login event
  login(event)
  {
    
  }

  logout()
  {
    this.loginService.logout();
  }

  changeCenterView(context: string)
  {
      Object.keys(this.visibility).forEach( v => this.visibility[v] = false)
      this.visibility[context] = true;  
  }

  getSideMenuModel(context: string): MenuItem[]
  {
    switch (context)
    {
      case this.SM_RESEARCH:
        return [
          {
            label: 'Plan Research',
            items: [
                      {label: 'Lookup Plans', command: (event) => { this.router.navigate(['research/plan']) }}
                  ]
          },
          {
            label: 'Member Research',
            items: [
                    {label: 'Lookup Members', command: (event) => { this.router.navigate(['research/member']) }}
                  ]
          },
          {
            label: 'Instance Research',
            items: [
                    {label: 'Lookup Instances', command: (event) => { this.router.navigate(['research/instance/instance']) }},
                    {label: 'Search Instances', command: (event) => { this.router.navigate(['research/instance/search'])}},
                    {label: 'Procedure Lookup', command: (event) => { this.router.navigate(['research/procedurelookup']) }}
                  ]
          },
          {
            label: 'Provider Research',
            items: 
            [
              {label: 'Manage Practices', command: (event) => {this.router.navigate(['research/provider']) }}
            ]
          }
        ]
      default:
        return [] as MenuItem[];
    }

  }

  getTopMenuModel(): MenuItem[]
  {    

    return [
        {label: 'Home', icon: 'pi pi-fw pi-home',command: (event) => { this.centerPanelType = this.CPT_SINGLE; this.router.navigate(['home']); } },
        {label: 'Encounters', icon: 'pi pi-folder-open', 
          items: 
                [
                  {label: 'Matching' ,command: (event) => { this.centerPanelType = this.CPT_SINGLE; this.router.navigate(['research/matching']) }},
                  {label: 'Encounter Lookup', command: (event) => {this.centerPanelType = this.CPT_SINGLE; this.router.navigate(['encounter/lookup'])}}
                ]
         },
        {label: 'Research', icon: 'pi pi-search-plus', command: (event) => { this.centerPanelType = this.CPT_SPLIT_2_10;  this.sideMenuModel = this.getSideMenuModel(this.SM_RESEARCH); this.changeCenterView('');}},
        {label: 'Reports', icon: 'pi pi-chart-bar', 
          items:
                [
                  {label: 'Report Definition Manager', command: (event) => { this.centerPanelType = this.CPT_SINGLE;  this.router.navigate(['reports/definereports']) }},
                  {label: 'Run Reports', command: (event) => { this.centerPanelType = this.CPT_SINGLE;  this.router.navigate(['reports/runreports']) }},
                  {label: 'View Reports', command: (event) => { this.centerPanelType = this.CPT_SINGLE;  this.router.navigate(['reports/viewreports']) }}
                ]},
        {label: 'Utilities', icon: 'pi pi-briefcase', disabled: !this.allowAdminAccess,
          items:
                [
                  {label: 'Transaction Manager', command: (event) => { this.centerPanelType = this.CPT_SINGLE; this.router.navigate(['utility/transactionmanager']) }},
                  {label: 'Invoice Manager', command: (event) => { this.centerPanelType = this.CPT_SINGLE; this.router.navigate(['utility/invoicemanager'])}},
                  {label: 'Company Manager', command: (event) => { this.centerPanelType = this.CPT_SINGLE; this.router.navigate(['utility/companymanager']) }},
                  {label: 'Mass Email Manager', command: (event) => { this.centerPanelType=this.CPT_SINGLE; this.router.navigate(['utility/massemailmanager'])}},
                  {label: 'Job Manager', command: (event) => { this.centerPanelType=this.CPT_SINGLE; this.router.navigate(['utility/jobmanager'])}},
                  {label: 'HomePage_v2',command: (event) => {this.centerPanelType = this.CPT_SINGLE; this .router.navigate(['home_v2'])}}
                ] 
        
        },
        {label: 'Logout', icon: 'pi pi-power-off', command: (event) => { this.logout()}}
      ] as MenuItem[];
  }

  
  // setUpTaskRouterCallbacks(ts: TwilioService) : void {
  //   this.worker.on( 'ready', function(worker) 
  //   {
      
  //     _this.updateButton( worker.activityName, "", _this );
  //   });

  //   this.worker.on('activity.update', function(worker) {
  //     _this.updateButton( worker.activityName, "", _this );
  //   });

  //   this.worker.on("reservation.created", function(reservation) {
  //     this.reservation = reservation;
  //     if ( reservation.task.attributes.type == "CHAT" )
  //     {
  //       _this.updateButton( "Chat", reservation.task.attributes.memberId, _this );
  //       //confirm("You have an incoming chat!");
  //       //reservation.accept();
  //       let url = environment.chatURI + "?conversationSid=" + reservation.task.attributes.conversationSid
  //                                       + "&taskSid=" + reservation.task.sid
  //                                       + "&userId=" + _this.currentUser.userId
  //                                       + "&name=" + _this.currentUser.firstName;
  //       _this.startChat(url);
  //     }
  //     else if ( reservation.task.attributes.type == "OUTBOUND" )
  //     {
  //       _this.updateButton( "Outbound", reservation.task.attributes.memberEndpoint, _this );
  //       _this.tcemsService.connectCall(
  //         reservation.task.attributes.nurseEndpoint,
  //         reservation.task.attributes.callerId,
  //         reservation.task.attributes.nurseEndpoint,
  //         reservation.task.attributes.memberEndpoint,
  //         "Connecting outbound call to " + reservation.task.attributes.memberFirstName + " " + reservation.task.attributes.memberLastName,
  //         25  // timeout (seconds)
  //       );
  //     }
  //     else  // incoming phone call
  //     {
  //       _this.updateButton( "Call", reservation.task.attributes.from.replace( "+1", "", _this ) );
  //       _this.taskrouterWhiteLabel = reservation.task.attributes.program.toUpperCase() + " " + reservation.task.attributes.from;
  //       // reservation.dequeue();

  //     }
  //   });

  //   this.worker.on("reservation.accepted", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Busy"][0].sid});
  //     _this.updateButton( "Busy", "", _this );
  //   });

  //   this.worker.on("reservation.rejected", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Away"][0].sid});
  //     _this.updateButton( "Away", "", _this );
  //   });

  //   this.worker.on("reservation.timeout", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Away"][0].sid});
  //     _this.updateButton( "Away", "", _this );
  //   });

  //   this.worker.on("reservation.canceled", function(reservation) {
  //     _this.worker.update({"ActivitySid": _this.activitySids["Away"][0].sid});
  //     _this.updateButton( "Away", "", _this );
  //   });

  //   this.worker.on("reservation.wrapup", function(reservation) {
  //     console.log("wrapup: " + _this.activitySids["WrapUp"][0].sid)
  //     _this.worker.update({"ActivitySid": _this.activitySids["WrapUp"][0].sid});
  //     _this.worker.completeTask(reservation.taskSid,
  //       function(error,completedTask) { });
  //     _this.updateTwilioNumber("");
  //     _this.endChat();
  //   });
    
  // }
  
  playChime() : void {
    let audio = new Audio();
    audio.src = "../assets/sounds/chime.mp3";
    audio.load();
    audio.play();
  }
  
  updateButton( activityName) : void {

    this.taskrouterButtonLabel = activityName;

    switch ( activityName )
    {
      case 'Away':
        this.taskrouterButtonClass = "p-button-danger";
        this.taskrouterWhiteLabelAndCid = "";
        this.phiSearchResults = null;
        break;
      case 'Ready':
        this.taskrouterButtonClass = "p-button-success";
        this.taskrouterWhiteLabelAndCid = "";
        this.phiSearchResults = null;
        break;
      case 'Call':
        this.taskrouterButtonClass = "p-button-warning";
        break;
      case 'Chat':
        this.taskrouterButtonClass = "p-button-help";
        break;
      case 'Outbound':
        this.taskrouterButtonClass = "p-button-secondary";
      case 'Busy':
        this.taskrouterButtonClass = "p-button-secondary";
        break;
      case "WrapUp":
        this.taskrouterButtonClass = "p-button-info";
        break;
      case "Desk":
        this.taskrouterButtonClass = "p-button-info";
        break;
      default:
        this.taskrouterButtonClass = "p-button-secondary";
        break;
    }

    
    //this.cdr.detectChanges();

  }

  showPhiResults(event)
  {
    console.log(event)
    //this.phiResults.toggle(null,this.twilioButton);
    this.showResults = !this.showResults;
  }

  phiSearch($event)
  {
    let q = $event.query;
    // console.log("Search:" + q);

    //Depending on the search string, we want to build our results a bit differently
    //Numbers mean that we're looking at member ids and/or encounter ids
    //strings mean that we're looking at member ids and/or phi

    let allPromises: Promise<PhiSearchResult[]>[] = [];

    if (q == null || q.length == 0 ) { return; } // empty query
    
      // Do Phi
      allPromises = [...allPromises, this.tcemsService.searchForPhi({query: q}).toPromise()
      .then
      (
        results =>
          {
            // console.log(results);
            return results.filter(p => (Object.assign(new Phi(),p).isSubscriber)).map
            (
              p =>
              {
                let phiResult = new PhiResult();
                phiResult.result = p;
                phiResult.phiId = p.phiId;
                return phiResult;
              }
            );
          }
      )
      .catch
      (
        error =>
        {
          console.log('PHI Search Failed: ' + error);
          // this.phiSearchResults = [];
          return null;
        }
      )];

    // Wait til we get everything back
    Promise.all(allPromises).then
    (
      //Set up our list of suggestions

      result =>
      {
        let flatResults:PhiSearchResult[] = [];
        result.forEach( subResult => {if (subResult != null) flatResults = [...flatResults, ...subResult]});
        this.phiSearchResults = [... flatResults];
        console.log(this.phiSearchResults);
      }
    )
  }


}
