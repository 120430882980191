<div class="category-header">{{header}}&nbsp;&nbsp;
  <div style="float:right; font-size: .9em">( {{phi?.phiId}} )</div>
</div>
<table *ngIf="phi != null" class="data-table">
  <tr *ngIf="editablePhi">
    <td class="field-name">
      <p-checkbox name="hasOptedOut" [binary]="true" [(ngModel)]="phi.hasOptedOut" (onChange)="updatePhiDnp($event,phi)"></p-checkbox>
    </td>
    <td colspan="4" style="font-size: .9em; font-style: italic" [ngStyle]="{'background-color': ( phi.hasOptedOut ) ? 'red':'white'}">Member Opted Out of Program</td>
  </tr>
  <tr>
    <td class="field-name">Name</td>
    <td class="field-data" colspan="4">
      <span *ngIf="(!phi?.isSubscriber) && (currentMemberSubscriber != null) && (!showAbbreviated)" style="float:right">
        <!--<a (click)="sub.toggle($event)" style="color:blue;font-weight:normal;font-size:.9em">Show Subscriber</a>-->
        <p-tag value="Subscriber" icon="pi pi-id-card" (click)="sub.toggle($event)" [style]="{'cursor': 'pointer'}"></p-tag>
        <p-overlayPanel #sub [showCloseIcon]="false" [style]="{width:'450px'}">
          <app-phi-summary [phi]="currentMemberSubscriber"
            header="SUBSCRIBER" [editablePhi]="false"></app-phi-summary>
        </p-overlayPanel>
      </span>
      <p-inplace [disabled]="!editablePhi" [closable]="true" (onActivate)="originalPhi['firstName']=phi.firstName;originalPhi['middleInitial']=phi.middleInitial;originalPhi['lastName']=phi.lastName"
                                                             (onDeactivate)="phi.firstName=originalPhi['firstName'];phi.middleInitial=originalPhi['middleInitial'];phi.lastName=originalPhi['lastName']" #phiEditor>
        <ng-template pTemplate="display">
          <span class="field-data">{{phi?.firstName}} {{phi?.middleInitial}} {{phi?.lastName}}</span>
          <span *ngIf="phi?.isSubscriber === false" style="vertical-align: top;color:red;font-weight:bold"> (DEP)</span>
        </ng-template>
        <ng-template pTemplate="content">
          <input type="text" [(ngModel)]="phi.firstName" pInputText style="width:100px">
          <input type="text" [(ngModel)]="phi.middleInitial" pInputText style="width:30px" [maxLength]="1">
          <input type="text" [(ngModel)]="phi.lastName" pInputText style="width:100px">
          <button type="button" pButton icon="pi pi-check" styleClass="p-button-success"
                  (click)="updatePhiName($event,phi); originalPhi['firstName']=phi.firstName;originalPhi['middleInitial']=phi.middleInitial;originalPhi['lastName']=phi.lastName; phiEditor.deactivate();"></button>
      </ng-template>
      </p-inplace>
    </td>
  </tr>
  <tr *ngIf="!showAbbreviated">
    <td class="field-name">Member ID</td>
    <td class="field-data" colspan="4">{{phi?.memberId}}<p-tag *ngIf="phi?.endUser != null" severity="success" value="Mobile App Registered" [rounded]="true" style="float:right"></p-tag></td>
  </tr>
  <tr *ngIf="!showAbbreviated">
    <td class="field-name" (click)="this.showAddressEditor=editablePhi" [style]="{'cursor': 'pointer'}">Address</td>
    <td style="background-color:white; ">
      <p-table [value]="phi?.addresses" (click)="this.showAddressEditor=editablePhi" [style]="{'cursor': 'pointer'}" styleClass="p-datatable-striped">
        <ng-template pTemplate="body" let-ad>
          <tr>
            <td *ngIf="ad.active" class="field-data">
              <p-tag *ngIf="ad?.addressType?.code != 1" severity="{{setColor(ad?.addressType?.code)}}" [rounded]="true" value="{{ad?.addressType?.code}}"></p-tag>
              {{ad.address1}} {{ad.address2}}<br>{{ad.city}}, {{ad.state}} {{ad.zipCode}}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <app-address-editor [phiId]="phi.phiId" [(addresses)]="phi.addresses" [editablePhi]="editablePhi"
                          [(showAddressEditor)]="showAddressEditor" [showMarketing]="true"></app-address-editor>
    </td>
  </tr>
  <tr>
    <td class="field-name" (click)="this.showPhoneEditor=editablePhi" [style]="{'cursor': 'pointer'}">Phone</td>
    <td style="background-color:white; ">
      <p-table [value]="phi?.phoneNumbers" (click)="this.showPhoneEditor=editablePhi" [style]="{'cursor': 'pointer'}" styleClass="p-datatable-striped">
        <ng-template pTemplate="body" let-ph>
          <tr>
            <td *ngIf="ph.active && ((!showAbbreviated) || (showAbbreviated && ph?.phoneType?.code == 1))" style="vertical-align: top;" class="field-data">
              <p-tag *ngIf="ph?.phoneType?.code != 1" severity="{{setColor(ph?.phoneType?.code)}}" [rounded]="true" value="{{ph?.phoneType.code}}"></p-tag>
              {{ph.phoneNumber}}
              <p-tag *ngIf="ph.isMobileNumber" severity="primary" [rounded]="true" value="Cell"></p-tag>
            </td>
            </tr>
        </ng-template>
      </p-table>
      <app-phone-editor [phiId]="phi?.phiId" [(phoneNumbers)]="phi.phoneNumbers" [editablePhi]="editablePhi"
                        [(showPhoneEditor)]="showPhoneEditor" [showMarketing]="true"></app-phone-editor>
    </td>
  </tr>
  <tr>
    <td class="field-name" (click)="this.showEmailEditor=editablePhi" [style]="{'cursor': 'pointer'}">Email</td>
    <td style="background-color:white; ">
      <p-table [value]="phi?.emailAddresses" (click)="this.showEmailEditor=editablePhi" [style]="{'cursor': 'pointer'}" styleClass="p-datatable-striped">
        <ng-template pTemplate="body" let-em>
          <tr>
            <td *ngIf="em.active && ((!showAbbreviated) || (showAbbreviated && em?.emailType?.code == 1))" style="vertical-align: top;" class="field-data">
              <p-tag *ngIf="em?.emailType?.code != 1" severity="{{setColor(em?.emailType?.code)}}" [rounded]="true" value="{{em?.emailType.code}}"></p-tag>
              {{em.emailAddress}}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <app-email-editor [phiId]="phi.phiId" [(emailAddresses)]="phi.emailAddresses" [editablePhi]="editablePhi"
                        [(showEmailEditor)]="showEmailEditor" [showMarketing]="true"></app-email-editor>
    </td>
  </tr>
  <tr *ngIf="!showAbbreviated">
    <td class="field-name">DOB</td>
    <td class="field-data" colspan="4">
      <p-inplace [disabled]="!editablePhi" [closable]="true" (onActivate)="originalDob=phi.dob"
      (onDeactivate)="phi.dob=originalDob" #dobEditor>>
        <ng-template pTemplate="display">
          <span class="field-data">{{phi?.dob | date:'MM/dd/yyyy'}}</span>
        </ng-template>
        <ng-template pTemplate="content">
          <p-calendar [(ngModel)]="phi.dob"></p-calendar>
          <button type="button" pButton icon="pi pi-check" styleClass="p-button-success"
          (click)="updateDob($event,phi); originalDob=phi.dob; dobEditor.deactivate();"></button>
          </ng-template>
      </p-inplace>
  </tr>
</table>
<div  *ngIf="!showAbbreviated" class="category-header">Employer
  <div style="float:right; font-size: .9em">( {{phi?.company?.companyId}} )</div>
</div>
<div *ngIf="phi.company == null">No Eligible Client Company Found</div>
<table *ngIf="(phi != null) && (phi.company != null) && (!showAbbreviated)" class="data-table">
  <tr>
    <td class="field-name">Client</td>
    <td class="field-data" colspan="4">{{phi.company.tier1Name}}
      <div *ngIf="!phi.company.active" style="color:#ff0000;font-weight:normal">[ Company is not active or does not have current contract ]</div>
    </td>
  </tr>
  <tr *ngIf="phi.company.tier >= 2">
    <td class="field-name">Tier 2</td>
    <td class="field-data" colspan="4">{{phi.company.tier2Name}}</td>
  </tr>
  <tr *ngIf="phi.company.tier >= 3">
    <td class="field-name">Tier 3</td>
    <td class="field-data" colspan="4">{{phi.company.tier3Name}}</td>
  </tr>
  <tr *ngIf="phi.company.tier >= 4">
    <td class="field-name">Tier 4</td>
    <td class="field-data" colspan="4">{{phi.company.tier4Name}}</td>
  </tr>
  <tr *ngIf="phi.company.tier >= 5">
    <td class="field-name">Tier 5</td>
    <td class="field-data" colspan="4">{{phi.company.tier5Name}}</td>
  </tr>
  <tr *ngIf="phi.company.tier >= 6">
    <td class="field-name">Tier 6</td>
    <td class="field-data" colspan="4">{{phi.company.tier6Name}}</td>
  </tr>
  <tr *ngIf="phi.company.tier >= 7">
    <td class="field-name">Tier 7</td>
    <td class="field-data" colspan="4">{{phi.company.tier7Name}}</td>
  </tr>
</table>


