import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TcemsService } from 'src/app/api/tcems.service';
import { User, UserState } from 'src/app/model/util/user';
import { selectCurrentUser, selectTwilioReservation, selectUserState } from 'src/app/state/app.state';
import { environment } from 'src/environments/environment';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { UserPresence } from 'src/app/model/util/user_presence';
import { Diary } from 'src/app/model/support/diary';
import { ReferenceCode } from 'src/app/model/support/reference_code';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  chatActive: boolean = false;


  public urlSafe: SafeResourceUrl;

  constructor
  (
    private domSanitizer : DomSanitizer,
    private store: Store,
    private tcemsService: TcemsService,   
    private router: Router,
    private cdr: ChangeDetectorRef

  ) 
  { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false

  }

  sliderOpen: boolean = false;


  currentUser$ = this.store.select(selectCurrentUser);
  currentUser : User;

  currentState$ = this.store.select(selectUserState);
  currentState : UserState;


  reservation$ = this.store.select(selectTwilioReservation);

  // allUsers: User[] = [];
  usersById: { [userId: number]: User } = {};
  presenceById: { [userId: number]: UserPresence } = {};
  badgeTypeById: { [userId: number]: string } = {};

  //Right Slider
  rightSlideClasses={'slider-closed':true, 'slider-open':false, 'right-slider':true, 'shadow-5':true }
  tabIndex: number = 0;

  userIds: number[] = [];
  sVal: string = "info";

  calculateBadgeType(status:UserPresence) : string
  {
    let s = status == null ? 'null' : status.status;
    if(s == 'Ringing' || s == 'Busy' || s == 'Call' || s == 'Chat' )
      return 'warning';  // yellow
    if( s == 'Away' || s === "null" || s == 'Vacation' )
      return 'danger';  // red
    if(s == 'Desk')
      return 'info';  // blue
    return 'success';  // green
  }

  ngOnInit(): void 
  {

    this.currentUser$.subscribe
    (
      u =>
      {
        if(this.currentUser == null && u != null && u.userState.activeTask.externalId != null)
        {
          let t = u.userState.activeTask;
          let url = environment.chatURI + "?conversationSid=" + t.attributes["conversationSid"]
          + "&conversationId=" + t.attributes["conversationId"]
          + "&taskSid=" + ""
          + "&userId=" + u.userId
          + "&name=" + u.firstName;
          this.startChat(url);          
        }

        this.currentUser = u;
      }
    );


    this.currentState$.subscribe
    (
      us => 
      {
        if(this.currentState != null)
        {

          if(this.currentState.activeTask != null && this.currentState.activeTask.externalId != null && (us.activeTask == null || us.activeTask.externalId == null))
          {
            this.endChat();
          }
        }

        this.currentState = Object.assign(new UserState(),us);
      }
    )

    this.reservation$.subscribe
    (
      r =>
      {
        if ( r.reservation!= null && r.reservation.task.attributes.type == "CHAT" )
          if ( r.reservationStatus == "Created" ) {
            this.tcemsService.sendMessage(r.reservation.task.attributes.conversationId,null,"Hi "+r.reservation.task.attributes.memberFirstName+",\nWhile we connect you to a nurse, why are you chatting today?");
          } else if ( r.reservationStatus == "Accepted" ) {
            this.tcemsService.sendMessage(r.reservation.task.attributes.conversationId,null,"Nurse "+this.currentUser.firstName+" has joined the chat.");
            let url = environment.chatURI + "?conversationSid=" + r.reservation.task.attributes.conversationSid
                                              + "&conversationId=" + r.reservation.task.attributes.conversationId
                                              + "&taskSid=" + r.reservation.task.sid
                                              + "&userId=" + this.currentUser.userId
                                              + "&name=" + this.currentUser.firstName;
              this.startChat(url);
          }
      }
    );

    this.tcemsService.getActiveUsers().toPromise()
    .then
    (
      u =>
      {
        u.forEach(user => this.usersById[user.userId] = user);
        this.usersById =  {...this.usersById};
      }
    );

    this.tcemsService.getAllUserPresece().toPromise()
    .then
    (
      r =>
      {
        console.log(r);
        r.forEach
        (
          p => 
          {
            this.presenceById[p.userId] = p;
            this.badgeTypeById[p.userId] = this.calculateBadgeType(p);
            this.userIds =  Object.keys(this.presenceById).map(i => parseInt(i));

          }
        );
        this.presenceById = {... this.presenceById};
        this.badgeTypeById = {... this.badgeTypeById};
      }
    );

    const presenceConnection = new HubConnectionBuilder().withUrl(environment.tcemsAPIBase + environment.harlowe.hubs.presence.endpoint).build();
    presenceConnection.start();
    presenceConnection.on
    ("UpdateUserPresence", (val:UserPresence) => 
      {
        //Remove the user presence from the list
        console.log(val); 
        this.badgeTypeById[val.userId] = this.calculateBadgeType(val);
        this.presenceById[val.userId] = val;

        //This, stupidly, makes the screen refresh...
        this.userIds = null;
        this.cdr.detectChanges();
        this.userIds = [... Object.keys(this.presenceById).map(i => parseInt(i))];
      }
    );
  }

  startChat(url: string) {
    // console.log(this.urlSafe);
    this.urlSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    this.chatActive = true;
    this.tabIndex = 1;  // chat
    this.rightSlideClasses['slider-open']=true;
    this.rightSlideClasses['slider-closed']=false;
    // this.url += this.activitySids["Busy"][0].sid;
    // console.log(this.url);
  }

  endChat()
  {
    this.chatActive = false;
    this.rightSlideClasses['slider-open']=false;
    this.rightSlideClasses['slider-closed']=true;
  }

  toggleRightSlider()
  {
    this.sliderOpen = !this.sliderOpen;
    this.rightSlideClasses['slider-closed'] = !this.rightSlideClasses['slider-closed'];
    this.rightSlideClasses['slider-open'] = !this.rightSlideClasses['slider-open'];
  }

  togglePhone()
  {
    if(this.sliderOpen)
    {
      if(this.tabIndex != 0)
      {
        this.tabIndex = 0;
      }
      else
      {
        this.toggleRightSlider();
      }
    }
    else
    {
      this.tabIndex = 0;
      this.toggleRightSlider();
    }
  }

  toggleChat()
  {
    if(this.sliderOpen)
    {
      if(this.tabIndex != 1)
      {
        this.tabIndex = 1;
      }
      else
      {
        this.toggleRightSlider();
      }
    }
    else
    {
      this.tabIndex = 1;
      this.toggleRightSlider();
    }
  }

  toggleDiary()
  {
    if(this.sliderOpen)
    {
      if(this.tabIndex != 2)
      {
        this.tabIndex = 2;
      }
      else
      {
        this.toggleRightSlider();
      }
    }
    else
    {
      this.tabIndex = 2;
      this.toggleRightSlider();
    }
  }
  
  loadEntityFromDiary(diary: Diary)
  {

    if (diary.referenceType.code == ReferenceCode.TYPE_ENCOUNTER)
    {
      //Okay, we're working with an encounter
      let encounterId: number = diary.referenceId;
      this.router.navigate(["encounter/"+encounterId.toString()]);
    }
    else if (diary.referenceType.code == ReferenceCode.TYPE_PHI)
    {
      //Okay, we're working with an encounter
      let phiId: number = diary.referenceId;
      this.router.navigate(["phi/"+phiId.toString()]);
    }
  }
}
