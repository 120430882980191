import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { TcemsService } from 'src/app/api/tcems.service';
import { Encounter } from 'src/app/model/encounter/encounter';
import { EncounterFax, EncounterFaxWrite } from 'src/app/model/encounter/encounterfax';
import { HEMIFileDescriptor } from 'src/app/model/util/filedescriptor';
import { User } from 'src/app/model/util/user';
import { selectCurrentUser } from 'src/app/state/app.state';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-fax-scroller',
  templateUrl: './fax-scroller.component.html',
  styleUrls: ['./fax-scroller.component.scss']
})
export class FaxScrollerComponent implements OnInit {

  currentUser$ = this.store.select(selectCurrentUser);
  currentUser : User;

  //@Input() encounter: number;
  @Input() encounter: Encounter;

  encounterFaxes: EncounterFax[] = [];
  encounterFiles: HEMIFileDescriptor[] = [];
  selectedFilesToSend: HEMIFileDescriptor[] =[];
  selectedFax: EncounterFax;
  newFaxVisible: boolean = false;
  pendingFax: EncounterFaxWrite= null;

  constructor(
    private tcems: TcemsService,
    public util: TcemsUtilitiesService,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void 
  {
    this.getEncounterFaxes();
    this.getEncounterFiles();
    this.currentUser$.subscribe(
      u => 
      {
        if(u != null)
        {
          this.currentUser = Object.assign(new User(),u); 
        }
      });

  }

  getEncounterFiles()
  {
    //Get files for the supplied encounter
    this.tcems.fetchEncounterFiles(this.encounter.encounterId).toPromise().then
    (
      el =>
      {
        this.encounterFiles = el;
      }
    );
  }

  faxFiles(ids: string[]) : HEMIFileDescriptor[]
  {
    return this.encounterFiles.filter(f => ids.includes(f.fileId) );
  }

  getEncounterFaxes()
  {

    //Get files for the supplied encounter
    this.tcems.fetchEncounterFaxes(this.encounter.encounterId).toPromise().then
    (
      el =>
      {
        this.encounterFaxes = el;
      }
    );
  }

  newFax()
  {
    this.pendingFax = new EncounterFaxWrite();
    this.selectedFilesToSend = [];
    this.newFaxVisible = true;
    this.pendingFax.from = this.currentUser.firstName + " " + this.currentUser.lastName + " - " + this.encounter.subscriberCompany.whiteLabelDetail.programName;
    this.pendingFax.subject = "Prior Authorization for " + this.encounter.patient.phi.firstName + " " + this.encounter.patient.phi.lastName
      + " (Enc# " + this.encounter.encounterId + ")";
    this.pendingFax.notes = "Attached is the updated prior auth for " + this.encounter.patient.phi.firstName + " " + this.encounter.patient.phi.lastName + ".\n\n"
      + "Thank you for your assistance.\n\nSincerely,\n"
      + this.currentUser.firstName + " " + this.currentUser.lastName + ", Nurse Navigator\n"
      + this.encounter.subscriberCompany.whiteLabelDetail.programName + "\n"
      + this.currentUser.username + "@" + this.encounter.subscriberCompany.whiteLabelDetail.emailSuffix + "\n"
      + "(" + this.currentUser.hemiPhoneNumber.substring(0,3) + ") " + this.currentUser.hemiPhoneNumber.substring(3,6) + "-" + this.currentUser.hemiPhoneNumber.substring(6);
  }

  cancelButton(event)
  {
    this.pendingFax = null;
    this.newFaxVisible = false;
    this.selectedFilesToSend = [];
    this.getEncounterFiles();
  }
  
  saveButton(event)
  {
    //Make sure the file ids are attached
    this.pendingFax.attachmentIds = this.selectedFilesToSend.map(f => f.fileId);
    this.sendFax().then
    (
      v =>
      {
        this.pendingFax=null;
        this.newFaxVisible = false;
        this.selectedFilesToSend = [];
        this.getEncounterFiles();
      }
    )
  }

  get  saveButtonActive() :boolean
  {
    if
    (
      this.selectedFilesToSend != null &&
      this.selectedFilesToSend.length > 0 && 
      this.pendingFax.attn != null &&
      this.pendingFax.faxNumber != null &&
      this.pendingFax.faxNumber.length >= 10 &&
      this.pendingFax.from != null &&
      this.pendingFax.notes != null &&
      this.pendingFax.subject != null
    )
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  previewFile(fd: HEMIFileDescriptor)
  {
    //show popup with invoice PDF
    this.tcems.downloadEncounterFile(this.encounter.encounterId,fd.fileId,fd.contentType)
    .then
    (
      blob =>
      {
        if (blob.size == 0)
        {
          this.util.displayError("Could Not Retrieve File","File Does Not Exist");          
          return;
        }
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url,"Preview","height=640,width=960,toolbar=no,menubar=no,scrollbars=no,location=no,status=no");
      }
    )
    .catch
    (
      e =>
      {
        console.log(e);
        this.util.displayError("Could Not Open File",e.error);
      }
    )    
  }

  async sendFax()
  {
    //send the fax
    this.tcems.sendFax(this.encounter.encounterId,this.pendingFax).toPromise().then(
      async ef =>
      {
        //monitor faxes for this encoutner and update when it's finally done
        this.encounterFaxes = [... this.encounterFaxes, ef];
        let complete: boolean = false;
        while(!complete)
        {
          let fl = await this.tcems.fetchEncounterFaxes(this.encounter.encounterId).toPromise();

          let fax = fl.find(f => f.id == ef.id);
          this.encounterFaxes[this.encounterFaxes.findIndex(f => f.id == ef.id)] = fax;
          if(fax.status == 'Complete' || fax.status == 'Failed')
          {
            complete = true;
          }
          else
          {
            await new Promise((resolve,reject) => setTimeout(resolve, 5000));
          }
            
        }
      }
    )
    



  }
  // fileUploaded(event)
  // {
  //   this.getEncounterFiles();
  // }

  // deleteFile(fd: HEMIFileDescriptor)
  // {
  //   this.tcems.deleteEncounterFile(this.encounter,fd.fileId).toPromise().then(fd => this.getEncounterFiles());
  // }

  // typeChanged(fd:HEMIFileDescriptor, event)
  // {
  //   this.tcems.changeEncounterFileType(this.encounter, fd.fileId,fd.fileType).toPromise().then(fd => this.getEncounterFiles());
  // }

  // // onRowEditSave(fd: HEMIFileDescriptor, index: number)
  // // {

  // // }

  // // onRowEditInit(fd: HEMIFileDescriptor)
  // // {
  // //   this.tempDescriptors[fd.fileId] = Object.assign(new HEMIFileDescriptor(),fd);
  // // }

  // // onRowEditCancel(fd: HEMIFileDescriptor, index:number)
  // // {

  // // }

  // viewFile(fd: HEMIFileDescriptor)
  // {
  //   //show popup with invoice PDF
  //   this.tcems.downloadEncounterFile(this.encounter,fd.fileId,fd.contentType)
  //   .then
  //   (
  //     blob =>
  //     {
  //       if (blob.size == 0)
  //       {
  //         this.util.displayError("Could Not Retrieve File","File Does Not Exist");          
  //         return;
  //       }
  //       let url = window.URL.createObjectURL(blob);
  //       let pwa = window.open(url);
  //     }
  //   )
  //   .catch
  //   (
  //     e =>
  //     {
  //       console.log(e);
  //       this.util.displayError("Could Not Open File",e.error);
  //     }
  //   )    
  // }

}
